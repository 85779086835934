import React from 'react';

import { Button, IconImage, LoadingLayer } from '@/core/features';
import { getCdnStaticImageUrl } from '@/core/features/image-v2/image-utils';
import { logger } from '@/core/features/logger/logger';
import { historyBack, isHistoryBackSupported } from '@/core/features/router/history';
import { getUrlPathWithSearch } from '@/core/utils/url';

import styles from './error-page.module.scss';

export type ErrorPageProps = {
    detail?: string;
    isPageLoading: boolean;
    statusCode: number;
};

export default function ErrorPage({ detail = 'Unknown', isPageLoading, statusCode = 501 }: ErrorPageProps) {
    const is404 = Number(statusCode) === 404;
    React.useEffect(() => {
        if (is404) {
            return;
        }
        logger.error(`ErrorPage was shown with statusCode ${statusCode}. Source: ${detail}`, {
            url: getUrlPathWithSearch(location.href),
        });
    }, [detail, is404, statusCode]);

    const headline = is404 ? 'Die Seite konnte leider nicht gefunden werden.' : 'Leider ist ein Fehler aufgetreten.';

    const handleBack = () => {
        if (isHistoryBackSupported()) {
            historyBack();
            return;
        }

        // eslint-disable-next-line fp/no-mutation
        location.href = '/';
    };

    return (
        <>
            {isPageLoading && <LoadingLayer />}
            <div className={`${styles.contentBoxWrapper}`}>
                <div className={'padding-20 bg-white border-radius-3'}>
                    <div className={`flex-start-vertical ${styles.contentWrapper}`}>
                        <div className={`flex-center ${styles.iconWrapper}`}>
                            <IconImage
                                alt={''}
                                height={200}
                                url={getCdnStaticImageUrl('/static-images/wave-Icon.png')}
                                width={200}
                            />
                        </div>
                        <div className={`flex-center-vertical ${styles.textWrapper}`}>
                            <h1 className={styles.headline}>{headline}</h1>
                            <p>
                                (Code: {statusCode}
                                {detail && ` Detail: ${detail}`})
                            </p>
                            <p className={styles.text}>
                                Vielleicht wurde die Seite umbenannt, gelöscht oder ist gerade nicht erreichbar. Bitte
                                überprüfe die eingegebene Adresse oder starte eine neue Suche.
                            </p>
                        </div>

                        <div
                            className={styles.buttonWrapper}
                            suppressHydrationWarning={true}
                        >
                            {is404 ? (
                                <Button
                                    onClick={handleBack}
                                    title={'zurück'}
                                />
                            ) : (
                                <Button
                                    onClick={() => location.reload()}
                                    title={'neu laden'}
                                />
                            )}
                            <Button
                                href={'/'}
                                linkType={'vanilla'}
                                title={'zur Startseite'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
